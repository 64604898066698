<template>
  <div class="panel bg-transparent">
    <div
      class="inner-wrap bg-transparent"
      style="position: absolute; bottom: 50px"
    >
      <div class="inner-panel p-4">
        <a href="/" class="logo">
          <img src="base/logo.png" height="40" :alt="appTitle" />
        </a>

        <template v-if="!resetPassword">
          <h5 class="title text-uppercase font-weight-bold mb-2 mt-4">
            <i
              class="bx bx-user-circle mr-1 text-6 position-relative top-5"
            ></i>
            Sign In
          </h5>
          <p class="mb-3">
            Please enter your Account Number, email, telephone or nickname and
            password to continue
          </p>
          <form @submit.prevent="logInUser" method="post">
            <div class="form-group mb-3">
              <label>Login name</label>
              <div class="input-group">
                <input
                  v-model="username"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Account Number, email, telephone or nickname"
                />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="bx bx-user text-4"></i>
                  </span>
                </span>
              </div>
            </div>

            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Password</label>
                <a
                  @click.prevent="resetPassword = true"
                  href="javascript:;"
                  class="float-right"
                  >Lost Password?</a
                >
              </div>
              <div class="input-group">
                <input
                  v-model="password"
                  type="password"
                  class="form-control form-control-lg"
                />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="bx bx-lock text-4"></i>
                  </span>
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-8">
                <!-- <div class="checkbox-custom checkbox-default">
                  <input id="RememberMe" v-model="rememberme" type="checkbox" />
                  <label class="cursor" for="RememberMe">Remember Me</label>
                </div> -->
              </div>
              <div class="col-sm-4 text-right mb-3">
                <button type="submit" class="btn btn-primary mt-2">
                  Sign In
                </button>
              </div>
            </div>
            <!-- 
            <span class="mt-3 mb-3 line-thru text-center text-uppercase">
              <span>or</span>
            </span> -->

            <!-- <div v-if="isLoggedIn" class="mb-1 text-center">
              <a class="btn btn-facebook mb-3 ml-1 mr-1" href="#"
                >Connect with <i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn btn-twitter mb-3 ml-1 mr-1" href="#"
                >Connect with <i class="fab fa-twitter"></i
              ></a>
            </div> -->
          </form>
        </template>
        <ResetPassword
          v-if="resetPassword"
          @completedPasswordReset="resetPassword = false"
          @cancelPasswordReset="resetPassword = false"
        />
        <p class="text-center text-muted mt-3 mb-3">
          &copy; Copyright 2021. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import $ from "jquery";
import ResetPassword from "@/components/ResetPassword.vue";
export default {
  components: { ResetPassword },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.isLoggedIn);
    const appTitle = computed(() => store.state.appTitle);
    const username = ref("");
    const password = ref("");
    const rememberme = ref("");
    const resetPassword = ref(false);

    const nums = [1, 2];
    const random = Math.floor(Math.random() * nums.length);

    const logInUser = () => {
      store.dispatch("logInUser", {
        username: username.value,
        password: password.value,
        rememberme: rememberme.value,
      });
    };
    onBeforeMount(() => {
      store.commit("hideLogoutIndicator");
      $("body")
        .css("background-color", "#8d1d1c")
        .css(
          "background-image",
          "url(base/img/login-pix-" + nums[random] + ".png)"
        )
        .css("background-position", "center top")
        .css("background-repeat", "no-repeat")
        .css("background-size", "fill");
    });
    onBeforeUnmount(() => {
      $("body")
        .css("background-color", "#ECEDF0")
        .css("background-image", "none");
    });
    onMounted(() => {
      store.commit("hideLogoutIndicator");
      $("body")
        .css("background-color", "#71100e")
        .css(
          "background-image",
          "url(base/img/login-pix-" + nums[random] + ".png)"
        )
        .css("background-position", "right top")
        .css("background-repeat", "no-repeat")
        .css("background-size", "fill");
    });
    return {
      isLoggedIn,
      logInUser,
      appTitle,
      username,
      password,
      rememberme,
      resetPassword,
    };
  },
};
</script>
<style scoped>
.body {
  background-color: #71100e !important;
}
.inner-panel {
  background-color: rgba(255, 255, 255, 0.8);
}
@media only screen and (min-width: 768px) {
  .panel {
    position: absolute;
    bottom: 30px;
    left: 10%;
    width: 400px;
    max-width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .panel {
    margin: auto;
    position: absolute;
    padding-top: 20%;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    width: 400px;
    max-width: 90%;
  }
}
</style>